.mat-calendar-body-cell:not(.mat-calendar-body-selected):not(.mat-calendar-body-disabled) {
  &:hover {
    span {
      color: var(--c-white)!important;
    }
  }
  &:focus {
    .mat-calendar-body-selected, .mat-calendar-body-today {
      color: var(--c-white)!important;
    }
  }
}
