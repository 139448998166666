.full-width {
  width: 100%;
}

.mdc-switch{
  --mdc-switch-track-height: 20px !important;
  --mdc-switch-track-shape:12px  !important;
  --mdc-switch-track-width:38px !important;

  --mdc-switch-handle-height: 17px !important;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 17px !important;
  .mdc-switch__handle-track{
    width: calc(100% - (var(--mdc-switch-handle-width) + 5px)) !important;
  }
}
.mdc-switch__handle{
  left: 3px !important;
}
 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--c-primary-40) !important;
  --mdc-switch-selected-handle-color: var(--c-primary-100) var(--c-primary-40) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--c-primary-40) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--c-primary-40) !important;
  --mdc-switch-selected-focus-handle-color: var(--c-primary-100) !important;
  --mdc-switch-selected-hover-handle-color: var(--c-primary-100) !important;
  --mdc-switch-selected-pressed-handle-color: var(--c-primary-100) !important;
  --mdc-switch-selected-focus-track-color: var(--c-primary-40) !important;
  --mdc-switch-selected-hover-track-color: var(--c-primary-40) !important;
  --mdc-switch-selected-pressed-track-color: var(--c-primary-40) !important;
  --mdc-switch-selected-track-color: var(--c-primary-40) !important;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--c-primary-40) !important;
  --mdc-linear-progress-track-color: var(--c-neutral-90) !important;
}

i.action-ic {
  font-size: 16px;
  position: relative;
  bottom: 5px;
}

.f-1 {
  flex: 1;
}

.d-flex {
  display: flex;
}

.fd-row {
  flex-direction: row;
}

.fd-column {
  flex-direction: column;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.jc-space-around {
  justify-content: space-around;
}

.jc-space-evenly {
  justify-content: space-evenly;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.ai-center {
  align-items: center;
}

.ai-space-between {
  align-items: space-between;
}

.ai-space-around {
  align-items: space-around;
}

.ai-space-evenly {
  align-items: space-evenly;
}
