button[color="primary"] {
  transition: all 150ms ease-in-out;


  color: var(--c-primary-40);
  font-weight: 600;

  &:hover {
    color: var(--c-primary-40);
  }


  &:focus {
    background-color: transparent;
  }

  &[disabled] {
    color: var(--c-neutral-60) !important;
  }

}


button[color="primary"] {
  transition: all 150ms ease-in-out;

  &[mat-flat-button],
  &[mat-raised-button] {
    color: #fff !important;
    font-weight: 600;

    &:hover {
      background-color: var(--c-primary-60) !important;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.22) !important;
    }

    &:focus {
      background-color: var(--c-primary-30) !important;
    }

    border-radius: 10px !important;

    &[disabled] {
      background-color: var(--c-neutral-80) !important;
      color: var(--c-text-tertiary) !important;
    }
  }
}

button[color="primary"] {
  transition: all 150ms ease-in-out;

  &[mat-stroked-button].mat-mdc-outlined-button {
    color: var(--c-primary-40);
    border-color: var(--c-neutral-50);
    font-weight: 600;

    &:hover {
      background-color: var(--c-on-primary-opacity-12) !important;
      border-color: var(--c-neutral-60) !important;
    }

    &:focus {
      background-color: var(--c-neutral-50) !important;
    }

    border-radius: 10px !important;

    &[disabled] {
      background-color: var(--c-neutral-80) !important;
      color: var(--c-text-tertiary) !important;
    }

  }
}