:root {
  --c-white: #fff;
  --c-black: #000;
  --c-blue: #0075ff;

  --c-text-primary: #0a0a0a;
  --c-text-secondary: #3c3c3c;
  --c-text-tertiary: #878787;

  //--c-dark-mode-dark-mode-bg: #0d0d0d;
  --c-bg: var(--c-white);
  //--c-dark-mode-ui-elements: #262626;
  --c-ui-elements: var(--c-white);

  --c-primary-0: #000;
  --c-primary-5: #001505;
  --c-primary-10: #00201b;
  --c-primary-15: #002d0f;
  --c-primary-20: #003730;
  --c-primary-25: #00443b;
  --c-primary-30: #005046;
  --c-primary-35: #005d52;
  --c-primary-40: #006b5e;
  --c-primary-50: #008677;
  --c-primary-60: #00a693;
  --c-primary-70: #30bfaa;
  --c-primary-80: #56dbc6;
  --c-primary-95: #b5ffef;
  --c-primary-90: #76f8e2;
  --c-primary-98: #e5fff8;
  --c-primary-99: #f3fffb;
  --c-primary-100: #fff;

  --c-error-0: #000;
  --c-error-5: #2d0001;
  --c-error-10: #410002;
  --c-error-15: #540003;
  --c-error-20: #690005;
  --c-error-25: #7e0007;
  --c-error-30: #93000a;
  --c-error-35: #a80710;
  --c-error-40: #ba1a1a;
  --c-error-50: #de3730;
  --c-error-60: #ff5449;
  --c-error-70: #ff897d;
  --c-error-80: #ffb4ab;
  --c-error-90: #ffdad6;
  --c-error-95: #ffedea;
  --c-error-98: #fff8f7;
  --c-error-99: #fffbff;
  --c-error-100: #fff;

  --c-neutral-0: #000;
  --c-neutral-na: #fff;
  --c-neutral-5: #0f120f;
  --c-neutral-10: #191c1b;
  --c-neutral-15: #242623;
  --c-neutral-20: #2d3130;
  --c-neutral-25: #383c3b;
  --c-neutral-30: #444746;
  --c-neutral-35: #505352;
  --c-neutral-40: #5c5f5e;
  --c-neutral-50: #747876;
  --c-neutral-70: #a9acaa;
  --c-neutral-60: #8e9190;
  --c-neutral-80: #c4c7c5;
  --c-neutral-90: #e0e3e1;
  --c-neutral-95: #eff1ef;
  --c-neutral-98: #f7faf8;
  --c-neutral-99: #fafdfa;
  --c-neutral-100: #fff;

  --c-neutral-variant-0: #000;
  --c-neutral-variant-5: #0c130c;
  --c-neutral-variant-10: #141d1b;
  --c-neutral-variant-15: #212820;
  --c-neutral-variant-20: #293230;
  --c-neutral-variant-25: #343d3b;
  --c-neutral-variant-30: #3f4946;
  --c-neutral-variant-35: #4b5452;
  --c-neutral-variant-40: #56605e;
  --c-neutral-variant-50: #6f7976;
  --c-neutral-variant-60: #899390;
  --c-neutral-variant-70: #a3adaa;
  --c-neutral-variant-80: #bec9c5;
  --c-neutral-variant-90: #dae5e1;
  --c-neutral-variant-95: #e9f3ef;
  --c-neutral-variant-98: #f1fcf8;
  --c-neutral-variant-99: #f4fffb;
  --c-neutral-variant-100: #fff;

  --c-on-primary-opacity-8: rgba(0, 55, 48, 0.08);
  --c-on-primary-opacity-12: rgba(0, 55, 48, 0.12);
  --c-on-primary-opacity-16: rgba(0, 55, 48, 0.16);

  --c-surface-tint-opacity-8: rgba(0, 107, 94, 0.08);
  --c-surface-tint-opacity-12: rgba(0, 107, 94, 0.12);
  --c-surface-tint-opacity-16: rgba(0, 107, 94, 0.16);

  --c-primary-container-opacity-8: rgba(0, 80, 70, 0.08);
  --c-primary-container-opacity-12: rgba(0, 80, 70, 0.12);
  --c-primary-container-opacity-16: rgba(0, 80, 70, 0.16);

  --c-surface-0: #fafdfa;
  --c-surface-1: #fafdfa;
  --c-surface-2: #fafdfa;
  --c-surface-3: #fafdfa;
  --c-surface-4: #fafdfa;
  --c-surface-5: #fafdfa;

  --c-gp-hermes-white: #fff;
  --c-gp-hermes-ref-primary-40: #006b5e;
  --c-gp-hermes-ref-neutral-95: #eff1ef;

  --ngb-datepicker-day-range-bg: #419bf9;
  --ngb-datepicker-day-focused-bg: #e6e6e6;
  --form-field-simulation-hover-bg: #e5e5e5;
  --box-shadow-color: #efefef;
  --bs-border-color: #dee2e6;
  --bs-light: #f8f9fa;
}
