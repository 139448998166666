@use "@angular/material" as mat;

@include mat.core();

$primary: (
  50: var(--c-primary-0),
  51: var(--c-primary-10),
  55: var(--c-primary-20),
  59: var(--c-primary-30),
  100: var(--c-primary-40),
  200: var(--c-primary-50),
  300: var(--c-primary-60),
  400: var(--c-primary-70),
  500: var(--c-primary-80),
  600: var(--c-primary-90),
  700: var(--c-primary-95),
  800: var(--c-primary-99),
  900: var(--c-primary-100),
  contrast: (
    50: var(--c-white),
    51: var(--c-white),
    55: var(--c-white),
    59: var(--c-white),
    100: var(--c-white),
    200: var(--c-white),
    300: var(--c-black),
    400: var(--c-black),
    500: var(--c-black),
    600: var(--c-black),
    700: var(--c-black),
    800: var(--c-black),
    900: var(--c-black),
  ),
);

$error: (
  50: var(--c-error-0),
  51: var(--c-error-10),
  55: var(--c-error-20),
  59: var(--c-error-30),
  100: var(--c-error-40),
  200: var(--c-error-50),
  300: var(--c-error-60),
  400: var(--c-error-70),
  500: var(--c-error-80),
  600: var(--c-error-90),
  700: var(--c-error-95),
  800: var(--c-error-99),
  900: var(--c-error-100),
  contrast: (
    50: var(--c-white),
    51: var(--c-white),
    55: var(--c-white),
    59: var(--c-white),
    100: var(--c-white),
    200: var(--c-white),
    300: var(--c-black),
    400: var(--c-black),
    500: var(--c-black),
    600: var(--c-black),
    700: var(--c-black),
    800: var(--c-black),
    900: var(--c-black),
  ),
);

$neutral: (
  50: var(--c-neutral-0),
  51: var(--c-neutral-10),
  55: var(--c-neutral-20),
  59: var(--c-neutral-30),
  100: var(--c-neutral-40),
  200: var(--c-neutral-50),
  300: var(--c-neutral-60),
  400: var(--c-neutral-70),
  500: var(--c-neutral-80),
  600: var(--c-neutral-90),
  700: var(--c-neutral-95),
  800: var(--c-neutral-99),
  900: var(--c-neutral-100),
  contrast: (
    50: var(--c-white),
    51: var(--c-white),
    55: var(--c-white),
    59: var(--c-white),
    100: var(--c-white),
    200: var(--c-black),
    300: var(--c-black),
    400: var(--c-black),
    500: var(--c-black),
    600: var(--c-black),
    700: var(--c-black),
    800: var(--c-black),
    900: var(--c-black),
  ),
);

$neutral-variant: (
  50: var(--c-neutral-variant-0),
  51: var(--c-neutral-variant-10),
  55: var(--c-neutral-variant-20),
  59: var(--c-neutral-variant-30),
  100: var(--c-neutral-variant-40),
  200: var(--c-neutral-variant-50),
  300: var(--c-neutral-variant-60),
  400: var(--c-neutral-variant-70),
  500: var(--c-neutral-variant-80),
  600: var(--c-neutral-variant-90),
  700: var(--c-neutral-variant-95),
  800: var(--c-neutral-variant-99),
  900: var(--c-neutral-variant-100),
  contrast: (
    50: var(--c-white),
    51: var(--c-white),
    55: var(--c-white),
    59: var(--c-white),
    100: var(--c-white),
    200: var(--c-white),
    300: var(--c-black),
    400: var(--c-black),
    500: var(--c-black),
    600: var(--c-black),
    700: var(--c-black),
    800: var(--c-black),
    900: var(--c-black),
  ),
);

// For color: define-palette(palette, default, lighter, darker, text)
$gp-hermes-light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette($primary, 100, 500, 55, 600),
    accent: mat.define-palette(mat.$green-palette, A200, A100, A400),
    warn: mat.define-palette($error, 100),
  )
));

$gp-hermes-dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette($primary, 100, 500, 55, 600),
    accent: mat.define-palette(mat.$green-palette, A200, A100, A400),
    warn: mat.define-palette($error, 59),
  )
));

body.light-mode {
  @include mat.all-component-themes($gp-hermes-light-theme);
}

body.dark-mode {
  @include mat.all-component-themes($gp-hermes-dark-theme);
}

