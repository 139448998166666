@font-face {
  font-family: Nunito;
  src: local(Nunito-Regular),
    url(../../fonts/static/Nunito-Regular.woff2) format("woff2"),
    url(../../fonts/static/Nunito-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Nunito;
  src: local(Nunito-Medium),
    url(../../fonts/static/Nunito-Medium.woff2) format("woff2"),
    url(../../fonts/static/Nunito-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Nunito;
  src: local(Nunito-SemiBold),
    url(../../fonts/static/Nunito-SemiBold.woff2) format("woff2"),
    url(../../fonts/static/Nunito-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-Black'),
  url('../../fonts/static/Nunito-Black.woff2') format('woff2'),
  url('../../fonts/static/Nunito-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-BlackItalic'),
  url('../../fonts/static/Nunito-BlackItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-Bold'),
  url('../../fonts/static/Nunito-Bold.woff2') format('woff2'),
  url('../../fonts/static/Nunito-Bold.ttf') format('truetype');
  font-weight: bold; /* Typically 700 */
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-BoldItalic'),
  url('../../fonts/static/Nunito-BoldItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-BoldItalic.ttf') format('truetype');
  font-weight: bold; /* Typically 700 */
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-ExtraBold'),
  url('../../fonts/static/Nunito-ExtraBold.woff2') format('woff2'),
  url('../../fonts/static/Nunito-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-ExtraBoldItalic'),
  url('../../fonts/static/Nunito-ExtraBoldItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-ExtraLight'),
  url('../../fonts/static/Nunito-ExtraLight.woff2') format('woff2'),
  url('../../fonts/static/Nunito-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-ExtraLightItalic'),
  url('../../fonts/static/Nunito-ExtraLightItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-Italic'),
  url('../../fonts/static/Nunito-Italic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-Italic.ttf') format('truetype');
  font-weight: var(--font-weight-regular, 400); /* Typically 400 */
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-Light'),
  url('../../fonts/static/Nunito-Light.woff2') format('woff2'),
  url('../../fonts/static/Nunito-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-LightItalic'),
  url('../../fonts/static/Nunito-LightItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-MediumItalic'),
  url('../../fonts/static/Nunito-MediumItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-SemiBoldItalic'),
  url('../../fonts/static/Nunito-SemiBoldItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-Variable'),
  url('../../fonts/static/Nunito-Variable.woff2') format('woff2'),
  url('../../fonts/static/Nunito-Variable.ttf') format('truetype');
  font-weight: var(--font-weight-regular, 400); /* Default to normal if variable isn't specified */
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito-VariableItalic'),
  url('../../fonts/static/Nunito-VariableItalic.woff2') format('woff2'),
  url('../../fonts/static/Nunito-VariableItalic.ttf') format('truetype');
  font-weight: var(--font-weight-regular, 400); /* Default to normal if variable isn't specified */
  font-style: italic;
}
