@import "assets/styles/reset.css";
@import "assets/styles/spacing.css";
@import "assets/styles/variables.scss";
@import "assets/styles/general.css";

@import "assets/styles/typography/font-faces.css";
@import "assets/styles/typography/headings.css";
@import "assets/styles/typography/bodies.css";

@import "assets/fonts/fontawesome/css/all.min.scss";

@import "assets/styles/colors/light_colors.scss";
@import "assets/styles/colors/dark_colors.scss";
@import "assets/styles/colors/material_theme.scss";
@import "assets/styles/colors/colors.css";
@import "assets/styles/breakpoints";

@import "assets/styles/components/button";
@import "assets/styles/components/checkbox";
@import "assets/styles/components/calendar";

.custom-loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #0000;
  border-right-color: var(--c-white);
  position: relative;
  animation: s4 0.5s infinite linear;
}

.custom-loader:before,
.custom-loader:after {
  content: "";
  position: absolute;
  inset: -4px;
  border-radius: 50%;
  border: inherit;
  animation: inherit;
  animation-duration: 1s;
}

.custom-loader:after {
  animation-duration: 2s;
}

@keyframes s4 {
  100% {
    transform: rotate(1turn)
  }
}
