
:root {
  .dark-mode {

    --c-white: #000;
    --c-black: #fff;
    --c-blue: #0075ff;

    --c-text-primary: #eff1ef;
    --c-text-secondary: #c4c7c5;
    --c-text-tertiary: #878787;

    //--c-dark-mode-dark-mode-bg: #0d0d0d;
    --c-bg: #0d0d0d;
    //--c-dark-mode-ui-elements: #262626;
    --c-ui-elements: #262626;

    --c-primary-0: #fff;
    --c-primary-5: #f3fffb;
    --c-primary-10: #e5fff8;
    --c-primary-15: #76f8e2;
    --c-primary-20: #b5ffef;
    --c-primary-25: #56dbc6;
    --c-primary-30: #30bfaa;
    --c-primary-35: #00a693;
    --c-primary-40: #008677;
    --c-primary-50: #006b5e;
    --c-primary-60: #005d52;
    --c-primary-70: #005046;
    --c-primary-80: #00443b;
    --c-primary-95: #003730;
    --c-primary-90: #002d0f;
    --c-primary-98: #00201b;
    --c-primary-99: #001505;
    --c-primary-100: #000;

    --c-error-0: #fff;
    --c-error-5: #fffbff;
    --c-error-10: #fff8f7;
    --c-error-15: #ffedea;
    --c-error-20: #ffdad6;
    --c-error-25: #ffb4ab;
    --c-error-30: #ff897d;
    --c-error-35: #ff5449;
    --c-error-40: #de3730;
    --c-error-50: #ba1a1a;
    --c-error-60: #a80710;
    --c-error-70: #93000a;
    --c-error-80: #7e0007;
    --c-error-90: #690005;
    --c-error-95: #540003;
    --c-error-98: #410002;
    --c-error-99: #2d0001;
    --c-error-100: #000;

    --c-neutral-0: #fff;
    --c-neutral-na: #000;
    --c-neutral-5: #fff;
    --c-neutral-10: #fafdfa;
    --c-neutral-15: #f7faf8;
    --c-neutral-20: #eff1ef;
    --c-neutral-25: #e0e3e1;
    --c-neutral-30: #c4c7c5;
    --c-neutral-35: #8e9190;
    --c-neutral-40: #a9acaa;
    --c-neutral-50: #747876;
    --c-neutral-70: #5c5f5e;
    --c-neutral-60: #505352;
    --c-neutral-80: #444746;
    --c-neutral-90: #383c3b;
    --c-neutral-95: #2d3130;
    --c-neutral-98: #242623;
    --c-neutral-99: #191c1b;
    --c-neutral-100: #0f120f;

    --c-neutral-variant-0: #fff;
    --c-neutral-variant-5: #f4fffb;
    --c-neutral-variant-10: #f1fcf8;
    --c-neutral-variant-15: #e9f3ef;
    --c-neutral-variant-20: #dae5e1;
    --c-neutral-variant-25: #bec9c5;
    --c-neutral-variant-30: #a3adaa;
    --c-neutral-variant-35: #899390;
    --c-neutral-variant-40: #6f7976;
    --c-neutral-variant-50: #56605e;
    --c-neutral-variant-60: #4b5452;
    --c-neutral-variant-70: #3f4946;
    --c-neutral-variant-80: #343d3b;
    --c-neutral-variant-90: #293230;
    --c-neutral-variant-95: #212820;
    --c-neutral-variant-98: #141d1b;
    --c-neutral-variant-99: #0c130c;
    --c-neutral-variant-100: #000;

    --c-on-primary-opacity-8: rgba(0, 55, 48, 0.08);
    --c-on-primary-opacity-12: rgba(0, 55, 48, 0.12);
    --c-on-primary-opacity-16: rgba(0, 55, 48, 0.16);

    --c-surface-tint-opacity-8: rgba(0, 107, 94, 0.08);
    --c-surface-tint-opacity-12: rgba(0, 107, 94, 0.12);
    --c-surface-tint-opacity-16: rgba(0, 107, 94, 0.16);

    --c-primary-container-opacity-8: rgba(0, 80, 70, 0.08);
    --c-primary-container-opacity-12: rgba(0, 80, 70, 0.12);
    --c-primary-container-opacity-16: rgba(0, 80, 70, 0.16);

    --c-surface-0: #191c1b;
    --c-surface-1: #191c1b;
    --c-surface-2: #191c1b;
    --c-surface-3: #191c1b;
    --c-surface-4: #191c1b;
    --c-surface-5: #191c1b;

    --c-gp-hermes-white: #fff;
    --c-gp-hermes-ref-primary-40: #006b5e;
    --c-gp-hermes-ref-neutral-95: #eff1ef;

    --ngb-datepicker-day-range-bg: #419bf9;
    --ngb-datepicker-day-focused-bg: #e6e6e6;
    --form-field-simulation-hover-bg: #3c403f;
    --box-shadow-color: #efefef;
    --bs-border-color: var(--c-neutral-80);
    --bs-light: #f8f9fa;
  }
}
