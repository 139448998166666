:root {
  --spacing-one-px: 1px;
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-s: 12px;
  --spacing-sx: 14px;
  --spacing-m: 16px;
  --spacing-mx: 18px;
  --spacing-l: 20px;
  --spacing-lx: 22px;
  --spacing-xl: 24px;
  --spacing-xl-1: 28px;
  --spacing-xxl-2: 32px;
  --spacing-xxl-3: 36px;
  --spacing-xxl-4: 40px;
  --spacing-xxl-5: 44px;
  --spacing-xxl-6: 48px;
  --spacing-xxl-7: 52px;
  --spacing-xxl-8: 56px;
  --spacing-xxl-9: 60px;
  --spacing-xxl-10: 64px;
  --spacing-xxl-12: 72px;
  --spacing-xxl-14: 80px;
  --spacing-xxl-19: 100px;
  --spacing-xxl-24: 120px;
  --spacing-xxl-29: 120px;
  --spacing-xxl-34: 140px;
  --spacing-xxl-39: 160px;
  --spacing-xxl-44: 180px;
  --spacing-xxl-49: 200px;
  --spacing-xxl-54: 220px;
  --spacing-xxl-59: 240px;
  --spacing-xxl-64: 260px;
  --spacing-xxl-69: 280px;
  --spacing-xxl-74: 300px;
  --spacing-xxl-79: 320px;
  --spacing-xxl-84: 340px;
  --spacing-xxl-89: 360px;
  --spacing-xxl-94: 380px;
  --spacing-xxl-99: 400px;
  --spacing-xxl-104: 420px;
  --spacing-xxl-109: 440px;
  --spacing-xxl-114: 460px;
  --spacing-xxl-119: 480px;
  --spacing-xxl-124: 500px;
  --spacing-xxl-129: 520px;
  --spacing-xxl-134: 540px;
  --spacing-xxl-139: 560px;
  --spacing-xxl-144: 580px;
  --spacing-xxl-149: 600px;
  --spacing-xxl-154: 620px;
  --spacing-xxl-159: 640px;
}
